import React, { useState, useEffect } from "react";
import { FaBagShopping } from "react-icons/fa6";
import Navbar from "../../navbar/navbar.jsx";
import { PiOfficeChair, PiSealWarningBold } from "react-icons/pi";
import { LuAlarmClock } from "react-icons/lu";
import { TbBeach } from "react-icons/tb";
import moment from "moment";
import Emp_Chart from "./emp_Chart.jsx";
import Leave_Details from "./leave_details.jsx";
import Emp_barchart from "./emp_barchart.jsx";
import usePermissions from "../../../permissions/permission.jsx";
import "animate.css";

const Dashboard = () => {
  const [data, setData] = useState({
    totalWorkforce: 0,
    presentWorkforce: 0,
    absentWorkforce: 0,
    lateArrivals: 0,
    inLeave: 0,
  });

  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = moment().format("YYYY-MM-DD");

        // Fetch attendance stats
        const response = await fetch(
          "https://back-trial-hris-ke.talentfort.live/v1/hris/employees/getAttendanceStats"
        );
        const result = await response.json();

        if (result.success) {
          const { totalWorkforce, presentWorkforce, absentWorkforce, inLeave } =
            result.data;

          // Update the state with fetched data
          setData((prevData) => ({
            ...prevData,
            totalWorkforce,
            presentWorkforce,
            absentWorkforce,
            inLeave,
          }));
        } else {
          console.error(
            "Error fetching attendance stats:",
            result.error || result
          );
        }

        // Fetch late in count with today's date
        const lateResponse = await fetch(
          `https://back-trial-hris-ke.talentfort.live/v1/hris/attendence/getLateInCount?date=${today}`
        );
        const lateResult = await lateResponse.json();

        if (lateResult.success) {
          const { late_in_count } = lateResult.data;

          setData((prevData) => ({
            ...prevData,
            lateArrivals: late_in_count,
          }));
        } else {
          console.error(
            "Error fetching late in count:",
            lateResult.error || lateResult
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">
            Dashboard - Employee Information Management
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <div className="text-[#3D0B5E] text-[20px] font-bold">
              {currentDate}
            </div>
          </div>
          <div className="text-[20px] font-bold">{currentTime}</div>
        </div>
      </div>

      <div className="mt-10">
        <div className="grid grid-cols-5 grid-flow-rows gap-5">
          {hasPermission(3000) && (
            <div className="p-3 rounded-xl border border-gray-100 bg-white shadow-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <FaBagShopping className="w-8 h-8" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Total Workforce</p>
                <p className="text-[50px] font-bold">{data.totalWorkforce}</p>
              </div>
            </div>
          )}

          {hasPermission(3001) && (
            <div className="p-3 rounded-xl border border-gray-100 bg-white shadow-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <PiOfficeChair className="w-8 h-8" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Present Workforce</p>
                <p className="text-[50px] font-bold">{data.presentWorkforce}</p>
              </div>
            </div>
          )}

          {hasPermission(3002) && (
            <div className="p-3 rounded-xl border border-gray-100 bg-white shadow-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <PiSealWarningBold className="w-8 h-8" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Absent Workforce</p>
                <p className="text-[50px] font-bold">{data.absentWorkforce}</p>
              </div>
            </div>
          )}

          {hasPermission(3003) && (
            <div className="p-3 rounded-xl border border-gray-100 bg-white shadow-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <LuAlarmClock className="w-8 h-8" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Late Arrivals</p>
                <p className="text-[50px] font-bold">{data.lateArrivals}</p>
              </div>
            </div>
          )}

          {hasPermission(3004) && (
            <div className="p-3 border border-gray-100 rounded-xl bg-white shadow-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <TbBeach className="w-8 h-8" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">In Leave</p>
                <p className="text-[50px] font-bold">{data.inLeave}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex gap-3 items-center w-full">
        {hasPermission(3005) && (
          <div className="flex-auto animate__fadeInLeft animate__animated -z-40">
            <Leave_Details data={data} />
          </div>
        )}

        {hasPermission(3006) && (
          <div className=" w-[400px] h-[450px] mr-3 animate__slideInUp animate__animated">
            <div className="shadow-xl rounded-xl ">
              <div className="mt-[-30px]">
                <Emp_Chart data={data} />
              </div>
            </div>
          </div>
        )}
        {hasPermission(3007) && (
          <div className="flex-auto mt-10 shadow-xl p-5 pl-8 rounded-xl animate__fadeInRight animate__animated">
            <Emp_barchart data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
