import React, { useState, useEffect } from "react";
import Navbar from "../../navbar/navbar";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import Papa from "papaparse";
import { saveAs } from "file-saver";

const History_Logged_Details = () => {
  const [historyData, setHistoryData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [searchID, setSearchID] = useState("");
  const rowsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://back-trial-hris-ke.talentfort.live/v1/hris/logs/audit-logs"
        );
        const data = await response.json();
        setHistoryData(data);
      } catch (error) {
        console.error("Error fetching Audit Logs data:", error);
      }
    };
    fetchData();
  }, []);

  // Filter data based on search terms and date range
  const filteredData = historyData.filter(
    (log) =>
      log.editor.toLowerCase().includes(searchName.toLowerCase()) &&
      log.employee_no.toString().includes(searchID) &&
      (!startDate ||
        !endDate ||
        moment(log.edit_timestamp).isBetween(
          moment(startDate).startOf("day"),
          moment(endDate).endOf("day"),
          null,
          "[]"
        ))
  );

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 border rounded-md ${
            currentPage === i ? "bg-gray-300" : "bg-white"
          }`}
        >
          {i}
        </button>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <span key="start-ellipsis" className="px-3 py-1 border rounded-md">
          ...
        </span>
      );
      pageNumbers.unshift(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`px-3 py-1 border rounded-md ${
            currentPage === 1 ? "bg-gray-300" : "bg-white"
          }`}
        >
          1
        </button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <span key="end-ellipsis" className="px-3 py-1 border rounded-md">
          ...
        </span>
      );
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`px-3 py-1 border rounded-md ${
            currentPage === totalPages ? "bg-gray-300" : "bg-white"
          }`}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  const renderCellValue = (value) => {
    return value !== null && value !== undefined ? value : "null";
  };

  const exportToCSV = () => {
    const csvData = filteredData.map((log) => ({
      ID: log.id,
      Editor: log.editor,
      "Edited Timestamp": moment(log.edit_timestamp).format("D-MMM-YY, h:mm a"),
      "Employee Number": log.employee_no,
      "Field Name": log.field_name,
      "Old Value": log.old_value,
      Department: log.department,
      "New Value": log.new_value,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "history_logged_details.csv");
  };

  return (
    <div className="mx-10 mt-5">
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">Employee Edit Details</p>
          <p className="text-[15px] font-semibold text-[#5AB2FF]">
            History Logged Details
          </p>
        </div>
      </div>

      <div className="mt-5">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[200px]"
                placeholder="Search by name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[150px]"
                placeholder="Search by ID"
                value={searchID}
                onChange={(e) => setSearchID(e.target.value)}
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
          </div>

          <div className="relative flex items-center gap-3">
            <div className="relative flex items-center gap-3">
              <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
                <FaCalendarAlt className="h-5 w-5 text-gray-400" />
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="d MMM, yyyy"
                  placeholderText="Start Date"
                  className="text-sm text-gray-600 focus:outline-none"
                />
                {startDate && (
                  <AiOutlineClose
                    onClick={() => setStartDate(null)}
                    className="text-gray-500 cursor-pointer"
                  />
                )}

                <span className="text-gray-400">-</span>

                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="d MMM, yyyy"
                  placeholderText="End Date"
                  className="text-sm text-gray-600 focus:outline-none"
                />
                {endDate && (
                  <AiOutlineClose
                    onClick={() => setEndDate(null)}
                    className="text-gray-500 cursor-pointer"
                  />
                )}
              </div>
            </div>
            <button
              className="ml-4 p-3  text-black px-4 py-2 rounded-md shadow-custom"
              onClick={exportToCSV}
            >
              Export CSV
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="p-4 mt-10">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Editor
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Edited Timestamp
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Employee Number
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Field Name
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Old Value
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  Department
                </th>
                <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                  New Value
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentData.map((log, index) => (
                <tr
                  key={log.id}
                  className={index % 2 === 0 ? "bg-purple-100" : ""}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                    {renderCellValue(log.editor)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {moment(log.edit_timestamp).format("D-MMM-YY, h:mm a")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {renderCellValue(log.employee_no)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                    {renderCellValue(log.field_name)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {renderCellValue(log.old_value)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {renderCellValue(log.department)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                    {renderCellValue(log.new_value)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between items-center py-3">
            <div>
              Showing{" "}
              {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0}{" "}
              to{" "}
              {currentPage * rowsPerPage > filteredData.length
                ? filteredData.length
                : currentPage * rowsPerPage}{" "}
              of {filteredData.length} logs
            </div>
            <div className="flex space-x-2">{renderPageNumbers()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History_Logged_Details;
