/** @format */

import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci"; // Importing CiSearch icon
import { MdKeyboardArrowDown } from "react-icons/md"; // Importing MdKeyboardArrowDown icon
import Leave_process_popup from "../leave_request/leave_process_popup";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import moment from "moment";
import Cookies from "js-cookie";
import usePermissions from "../../../permissions/permission";

const Leave_approve_table = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [departments, setDepartments] = useState([]); // State to store department names
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [selectedDepartment, setSelectedDepartment] =
    useState("All Department");
  const [selectedDate, setSelectedDate] = useState(""); // State to store selected date
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedEmployeeNo, setSelectedEmployeeNo] = useState(null);
  const rowsPerPage = 10;
  const supervisorId = Cookies.get("supervisorId");
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const supervisorId = Cookies.get("supervisorId");
    console.log("Supervisor ID from cookies:", supervisorId);

    if (!supervisorId) {
      console.error("Supervisor ID not found in cookies");
    } else {
      fetchDepartments();
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      if (!supervisorId) {
        console.error("Supervisor ID not found in cookies");
        return;
      }

      console.log("Supervisor ID used in fetchData:", supervisorId);

      const response = await fetch(
        `https://back-trial-hris-ke.talentfort.live/v1/hris/leave/GetLeaveBySupervisorId?supervisor_id=${supervisorId}`
      );

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Fetched Data:", result); // Add this line to log the fetched data

      setData(result.data); // Assuming result.data contains the array of employees
      setFilteredData(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await fetch(
        "https://back-trial-hris-ke.talentfort.live/v1/hris/employees/getemployeebasicdetails"
      );
      const result = await response.json();
      // Extract unique department names
      const uniqueDepartments = [
        ...new Set(result.map((employee) => employee.department_name)),
      ];
      setDepartments(uniqueDepartments);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [searchInput, selectedDepartment, selectedDate]);

  const handleFilter = () => {
    const newFilteredData = data.filter((employee) => {
      const matchesName = employee.employee_fullname
        .toLowerCase()
        .includes(searchInput.toLowerCase()); // Filter by Employee Name
      const matchesDepartment =
        selectedDepartment === "All Department" ||
        employee.department === selectedDepartment;
      const matchesDate =
        !selectedDate || employee.requested_date === selectedDate; // Filter by selected date
      return matchesName && matchesDepartment && matchesDate;
    });

    // Sort by requested_date in ascending order
    newFilteredData.sort((a, b) =>
      moment(a.requested_date).isBefore(moment(b.requested_date)) ? -1 : 1
    );

    setFilteredData(newFilteredData);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    handleFilter();
    setIsDropdownOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const togglePopup = (employeeNo = null) => {
    setSelectedEmployeeNo(employeeNo);
    setIsFormOpen(!isFormOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getLeaveTypeClass = (leaveType) => {
    if (!leaveType) {
      return "bg-gray-100 text-gray-800"; // Default class for undefined or empty leave types
    }

    switch (leaveType.toLowerCase()) {
      case "casual":
        return "bg-orange-400 text-white";
      case "annual":
        return "bg-green-100 text-green-800";
      case "special":
        return "bg-pink-500 text-white";
      case "medical":
        return "bg-pink-100 text-pink-800";
      default:
        return "bg-gray-100 text-gray-800"; // Default class for other types
    }
  };

  const getActionTypeClass = (actionType) => {
    switch (actionType) {
      case "APPROVED":
        return "bg-orange-400 text-white";
      case "NOT APPROVED":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800"; // Default class for other types
    }
  };

  const exportToCSV = () => {
    const csvData = filteredData.map((employee) => ({
      ID: employee.employee_no,
      EmployeeName: employee.employee_fullname,
      Department: employee.department,
      DateOfLeaveApplied: employee.requesting_date,
      LeaveCategory: employee.leave_type,
      LeaveRequestedDate: employee.requested_date,
      Reason: employee.reason,
      Status: employee.approved_status_1,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "leave_approve_data.csv");
  };

  return (
    <div className="p-4">
      <div className="flex justify-between item-center mt-3">
        <div className="flex gap-4 items-center mt-5">
          <div className="relative">
            <input
              className="border border-black rounded-xl p-2 pl-10 w-[325px]"
              placeholder="Search by Employee Name"
              value={searchInput}
              onChange={handleSearchChange}
            />
            <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
          </div>
        </div>

        <div className="flex gap-4 items-center mt-5">
          <div className="relative">
            <button
              className="p-3 border border-black rounded-[12px]"
              onClick={toggleDropdown}
            >
              <div className="flex gap-3 items-center">
                <div>{selectedDepartment}</div>
                <MdKeyboardArrowDown />
              </div>
            </button>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                <ul>
                  <li
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleDepartmentSelect("All Department")}
                  >
                    All Department
                  </li>
                  {departments.map((department, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handleDepartmentSelect(department)}
                    >
                      {department}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div>
            <input
              type="date"
              className="border border-black rounded-xl p-2"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
          <div>
            {hasPermission(3410) && (
              <button
                className="p-2 border border-black rounded-[12px]  text-black"
                onClick={exportToCSV}
              >
                Export CSV
              </button>
            )}
          </div>
        </div>
      </div>

      <table className="min-w-full divide-y divide-gray-200 mt-12">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              ID
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Employee Name
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Department
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Date of Leave Applied
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leave Category
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leave Requested Date
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Leaves taken under each leave category
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Reason
            </th>
            <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentData.map((employee) => (
            <tr key={employee.employee_no}>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {employee.employee_no}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                {employee.employee_fullname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-900">
                <span className="px-2 inline-flex text-md leading-5 font-semibold rounded-full">
                  {employee.department}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {moment(employee.requesting_date).format("D-MMM-YY")}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                <span
                  className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full ${getLeaveTypeClass(
                    employee.leave_type
                  )}`}
                >
                  {employee.leave_type}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {moment(employee.requested_date).format("D-MMM-YY")}
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500 font-semibold underline cursor-pointer">
                {hasPermission(3400) && (
                  <button
                    className="text-black border-none"
                    onClick={() => togglePopup(employee.employee_no)}
                  >
                    Click
                  </button>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                {employee.reason}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                <span
                  className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full ${getActionTypeClass(
                    employee.approved_status_1
                  )}`}
                >
                  {employee.approved_status_1}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-between items-center py-3">
        <div>
          Showing{" "}
          {currentData.length > 0 ? (currentPage - 1) * rowsPerPage + 1 : 0} to{" "}
          {currentPage * rowsPerPage > filteredData.length
            ? filteredData.length
            : currentPage * rowsPerPage}{" "}
          of {filteredData.length} employees
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === page ? "bg-gray-300" : "bg-white"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>

      {isFormOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <Leave_process_popup
              togglePopup={togglePopup}
              employeeNo={selectedEmployeeNo}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Leave_approve_table;
