/** @format */

import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import moment from "moment";

const Time_And_Attendance_Table = ({ selectedDate }) => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [departments, setDepartments] = useState([]);
  const rowsPerPage = 20;

  // Fetch attendance data
  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://back-trial-hris-ke.talentfort.live/v1/hris/attendence/searchAttendanceByDate?date=${selectedDate}`
        );
        const result = await response.json();
        setAttendanceData(result.data || []);
        setFilteredData(result.data || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, [selectedDate]);

  // Fetch departments
  useEffect(() => {
    fetch(
      "https://back-trial-hris-ke.talentfort.live/v1/hris/employees/getemployeebasicdetails"
    )
      .then((response) => response.json())
      .then((data) => {
        const uniqueDepartments = [
          ...new Set(data.map((employee) => employee.department_name)),
        ];
        setDepartments(uniqueDepartments);
      })
      .catch((error) => console.error("Error fetching employee data:", error));
  }, []);

  // Filter data based on search query and department selection
  useEffect(() => {
    let filtered = attendanceData;

    if (searchQuery) {
      filtered = filtered.filter((row) =>
        row.employee_id.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedDepartment !== "All") {
      filtered = filtered.filter(
        (row) => row.department_name === selectedDepartment
      );
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchQuery, selectedDepartment, attendanceData]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const getStatusStyle = (actionType) => {
    switch (actionType) {
      case "check-in":
        return "bg-[#FFE9D0] p-1";

      case "grace-in":
        return "bg-[#FFFED3] p-1";

      case "short in":
        return "bg-[#BBE9FF] p-1";

      case "half-in":
        return "bg-[#FABC3F] text-white p-1";

      case "check-out":
        return "bg-[#E85C0D] text-white p-1";

      case "half-out":
        return "bg-[#430873] text-white p-1";

      case "short-out":
        return "bg-[#B1AFFF] text-white p-1";

      case "unknown":
        return "bg-[#44027b] text-white p-1";

      case "normal in":
        return "bg-[#57e65d] text-white p-1";

      case "short in/half out":
        return "bg-[#DDF616] text-white p-1";

      default:
        return "bg-gray-100 text-gray-800"; // Default class for other types
    }
  };

  return (
    <div className="p-5 rounded-xl">
      <h2 className="text-xl font-semibold mb-4">Attendance Table</h2>

      <div className="mt-5">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center mt-5">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by ID"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>

            <div className="relative">
              <select
                className="border border-black rounded-xl p-2 w-[325px]"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                <option value="All">All Departments</option>
                {departments.length > 0 ? (
                  departments.map((dept, index) => (
                    <option key={index} value={dept}>
                      {dept}
                    </option>
                  ))
                ) : (
                  <option>Loading departments...</option>
                )}
              </select>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-md font-bold text-gray-500 uppercase tracking-wider">
                Employee ID
              </th>
              <th className="px-6 py-3 text-left text-md font-bold text-gray-500 uppercase tracking-wider">
                Employee Name
              </th>
              <th className="px-6 py-3 text-left text-md font-bold text-gray-500 uppercase tracking-wider">
                Department
              </th>

              <th className="px-6 py-3 text-left text-md font-bold text-gray-500 uppercase tracking-wider">
                Check-in Time
              </th>
              <th className="px-6 py-3 text-left text-md font-bold text-gray-500 uppercase tracking-wider">
                Check-out Time
              </th>
              <th className="px-6 py-3 text-left text-md font-bold text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-md font-bold text-gray-500 uppercase tracking-wider">
                Address
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentRows.map((row, index) => (
              <tr
                key={row._id}
                className={index % 2 === 0 ? "bg-blue-100" : "bg-white"}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  {row.employee_id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {row.employee_fullname}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {row.department}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                  {moment(row.checkIN_time).format("h:mm a")}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
                  {row.checkOUT_time
                    ? moment(row.checkOUT_time).format("h:mm a")
                    : "No check-out recorded"}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-md text-black">
                  <span
                    className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full ${getStatusStyle(
                      row.status
                    )}`}
                  >
                    {row.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{row.address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 border rounded"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {Math.ceil(filteredData.length / rowsPerPage)}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) =>
              Math.min(prev + 1, Math.ceil(filteredData.length / rowsPerPage))
            )
          }
          disabled={
            currentPage === Math.ceil(filteredData.length / rowsPerPage)
          }
          className="px-3 py-1 border rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Time_And_Attendance_Table;
