import React, { useState, useEffect } from "react";
import { FaBagShopping } from "react-icons/fa6";
import Navbar from "../navbar/navbar.jsx";
import { PiOfficeChair, PiSealWarningBold } from "react-icons/pi";
import { LuAlarmClock } from "react-icons/lu";
import { TbBeach } from "react-icons/tb";
import moment from "moment";
import Time_And_Attendance_Table from "./time_and_attendance_table.jsx";
import usePermissions from "../../permissions/permission.jsx";

const Time_And_Attendance = () => {
  const [data, setData] = useState({
    totalWorkforce: 0,
    presentWorkforce: 0,
    absentWorkforce: 0,
    lateArrivals: 0,
    inLeave: 0,
  });

  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = moment().format("YYYY-MM-DD");

        // Fetch attendance stats
        const response = await fetch(
          "https://back-trial-hris-ke.talentfort.live/v1/hris/employees/getAttendanceStats"
        );
        const result = await response.json();

        if (result.success) {
          const { totalWorkforce, presentWorkforce, inLeave } = result.data;

          // Update the state with fetched data
          setData((prevData) => ({
            ...prevData,
            totalWorkforce,
            presentWorkforce,
            inLeave,
          }));
        } else {
          console.error(
            "Error fetching attendance stats:",
            result.error || result
          );
        }

        // Fetch absent workforce count with today's date as startDate and endDate
        const absentResponse = await fetch(
          `https://back-trial-hris-ke.talentfort.live/v1/hris/attendence/getNotAttendCount?startDate=${today}&endDate=${today}`
        );
        const absentResult = await absentResponse.json();

        if (absentResult.not_attended_count !== undefined) {
          setData((prevData) => ({
            ...prevData,
            absentWorkforce: absentResult.not_attended_count,
          }));
        } else {
          console.error(
            "Error fetching absent workforce count:",
            absentResult.error || absentResult
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchLateInCount = async () => {
      try {
        const today = moment().format("YYYY-MM-DD");

        // Fetch late in count with today's date
        const response = await fetch(
          `https://back-trial-hris-ke.talentfort.live/v1/hris/attendence/getLateInCount?date=${today}`
        );
        const result = await response.json();

        if (result.success) {
          const { late_in_count } = result;

          // Update the state with fetched late in count
          setData((prevData) => ({
            ...prevData,
            lateArrivals: late_in_count,
          }));
        } else {
          console.error(
            "Error fetching late in count:",
            result.error || result
          );
        }
      } catch (error) {
        console.error("Error fetching late in count:", error);
      }
    };
    fetchLateInCount();
  }, []);

  return (
    <div className="mx-10 mt-5">
      {/* second layer */}
      <div className="flex justify-between items-center mt-6">
        <div>
          <p className="text-[30px] font-semibold">
            Time and Attendance - Overview
          </p>
        </div>
      </div>

      {/* card layer */}
      <div className="mt-10">
        <div className="grid grid-cols-5 grid-flow-rows gap-5">
          {/* card 1 */}
          {hasPermission(3060) && (
            <div className="border border-gray-100 bg-white shadow-xl p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <FaBagShopping className="w-8 h-8" />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p className="mt-5 font-semibold">Total Workforce</p>
                <p className="text-[50px] font-bold">{data.totalWorkforce}</p>
              </div>
            </div>
          )}
          {/* card 2 */}
          {hasPermission(3061) && (
            <div className="border border-gray-100 bg-white shadow-xl p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <PiOfficeChair className="w-8 h-8" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Present Workforce</p>
                <p className="text-[50px] font-bold">{data.presentWorkforce}</p>
              </div>
            </div>
          )}
          {/* card 3 */}
          {hasPermission(3062) && (
            <div className="border border-gray-100 bg-white shadow-xl p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <PiSealWarningBold className="w-8 h-8" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Absent Workforce</p>
                <p className="text-[50px] font-bold">{data.absentWorkforce}</p>
              </div>
            </div>
          )}
          {/* card 4 */}
          {hasPermission(3063) && (
            <div className="border border-gray-100 bg-white shadow-xl p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <LuAlarmClock className="w-8 h-8" />
                </div>
              </div>

              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Late Arrivals</p>
                <p className="text-[50px] font-bold">{data.lateArrivals}</p>
              </div>
            </div>
          )}

          {/* card 5 */}
          {hasPermission(3064) && (
            <div className="border border-gray-100 bg-white shadow-xl p-3 rounded-xl">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <TbBeach className="w-8 h-8" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">In Leave</p>
                <p className="text-[50px] font-bold">{data.inLeave}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* lower layer */}
      <div className="mt-5">
        <Time_And_Attendance_Table
          selectedDate={moment().format("YYYY-MM-DD")}
        />
      </div>
    </div>
  );
};

export default Time_And_Attendance;
