import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { RiDownloadCloud2Line } from "react-icons/ri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import usePermissions from "../../permissions/permission";

const CheckinCheckoutReportTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const { hasPermission } = usePermissions();

  const rowsPerPage = 12;

  const getStatusStyle = (actionType) => {
    switch (actionType) {
      case "check-in":
        return "bg-[#FFE9D0] p-1";

      case "grace-in":
        return "bg-[#FFFED3] p-1";

      case "short in":
        return "bg-[#BBE9FF] p-1";

      case "half-in":
        return "bg-[#FABC3F] text-white p-1";

      case "check-out":
        return "bg-[#E85C0D] text-white p-1";

      case "half-out":
        return "bg-[#430873] text-white p-1";

      case "short-out":
        return "bg-[#B1AFFF] text-white p-1";

      case "unknown":
        return "bg-[#44027b] text-white p-1";

      case "normal in":
        return "bg-[#57e65d] text-white p-1";

      default:
        return "bg-gray-100 text-gray-800"; // Default class for other types
    }
  };

  // Fetch data from the API
  const handleFetchData = async () => {
    if (startDate && endDate) {
      setIsLoading(true);
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      try {
        const response = await fetch(
          `https://back-trial-hris-ke.talentfort.live/v1/hris/attendence/getAttendanceHistroy?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        );
        const result = await response.json();
        if (result.success) {
          const mappedData = result.data.map((item) => ({
            employee_id: item.employee_id,
            checkIN_time: item.checkIN_time,
            checkOUT_time: item.checkOUT_time,
            status: item.status,
            department: item.department || "- -",
            address: item.address || "- -",
          }));
          setData(mappedData);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please select both start and end dates.");
    }
  };

  useEffect(() => {
    handleFetchData(); // Fetch data when component mounts
  }, []);

  // Export to Excel functionality
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Attendance Data");

    // Define headers
    worksheet.columns = [
      { header: "Date", key: "Date", width: 30 },
      { header: "Employee ID", key: "EmployeeID", width: 30 },
      { header: "Check-In Time", key: "CheckInTime", width: 30 },
      { header: "Check-Out Time", key: "CheckOutTime", width: 30 },
      { header: "Status", key: "Status", width: 30 },
      { header: "Department", key: "Department", width: 30 },
      { header: "Address", key: "Address", width: 30 },
    ];

    // Add rows
    data.forEach((employee) => {
      worksheet.addRow({
        Date: moment(employee.checkIN_time).format("YYYY-MM-DD"),
        EmployeeID: employee.employee_id,
        CheckInTime: employee.checkIN_time,
        CheckOutTime: employee.checkOUT_time || "- -",
        Status: employee.status,
        Department: employee.department,
        Address: employee.address,
      });
    });

    // Apply header styles
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "6d709c" } };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "f3f4f6" },
      };
      cell.alignment = { horizontal: "center" };
    });

    // Apply row styling (alternative row colors)
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber !== 1) {
        const isEvenRow = rowNumber % 2 === 0;
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: isEvenRow ? "f4e5ff" : "FFFFFFFF" },
          };
          cell.alignment = { horizontal: "center", vertical: "middle" };
        });
        row.height = 30;
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "attendance_data.xlsx");
  };

  // Filtered employee data based on search, department, and status
  const filteredEmployeeData = data.filter((employee) => {
    const matchesSearch = employee.employee_id
      ? employee.employee_id.toLowerCase().includes(searchQuery.toLowerCase())
      : false;

    const matchesDepartment =
      selectedDepartment === "" || employee.department === selectedDepartment;

    const matchesStatus =
      selectedStatus === "" || employee.status === selectedStatus;

    return matchesSearch && matchesDepartment && matchesStatus;
  });

  const departments = [...new Set(data.map((employee) => employee.department))];

  const currentData = filteredEmployeeData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const totalPages = Math.ceil(filteredEmployeeData.length / rowsPerPage);

  // Pagination and Status Filter
  const handlePageChange = (page) => setCurrentPage(page);
  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  return (
    <div className="p-4">
      <div className="mt-5 mb-5">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <div className="relative">
              <input
                className="border border-black rounded-xl p-3 pl-10 w-[200px]"
                placeholder="Search by Employee ID"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <CiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-500" />
            </div>
            <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="Start Date"
              />
              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                className="text-sm text-gray-600 focus:outline-none"
                placeholderText="End Date"
              />
            </div>
            <button
              className=" text-black px-4 py-2 rounded-md shadow-custom"
              onClick={handleFetchData}
            >
              Get Data
            </button>
          </div>

          <div className="relative flex items-center gap-3 mt-5">
            {hasPermission(3530) && (
              <button
                className=" text-black px-4 py-2 rounded-md shadow-custom"
                onClick={exportToExcel}
              >
                <div className="flex gap-3 items-center">
                  <div>Export Document</div>
                  <RiDownloadCloud2Line />
                </div>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="overflow-x-auto w-[100%] ">
        {isLoading ? (
          <div className="text-center font-semibold py-10">
            Loading please wait...
          </div>
        ) : (
          <>
            <div className="flex justify-center space-x-4 mb-4">
              <button
                className={`px-3 py-1 rounded-md ${
                  selectedStatus === ""
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => handleStatusFilter("")}
              >
                All
              </button>
              <button
                className={`px-3 py-1 rounded-md ${
                  selectedStatus === "late in"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => handleStatusFilter("late in")}
              >
                Late IN
              </button>
              <button
                className={`px-3 py-1 rounded-md ${
                  selectedStatus === "early out"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => handleStatusFilter("early out")}
              >
                Early OUT
              </button>
              <button
                className={`px-3 py-1 rounded-md ${
                  selectedStatus === "missing in"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => handleStatusFilter("missing in")}
              >
                Missing IN
              </button>
              <button
                className={`px-3 py-1 rounded-md ${
                  selectedStatus === "missing out"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => handleStatusFilter("missing out")}
              >
                Missing OUT
              </button>
            </div>
            <table className="divide-y divide-gray-200 w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Employee ID
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Check-In Time
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Check-Out Time
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Department
                  </th>
                  <th className="px-6 py-3 text-left text-md font-semibold text-gray-500 uppercase tracking-wider">
                    Address
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentData.map((employee, index) => {
                  const rowClass = index % 2 === 0 ? "bg-blue-100" : "bg-white";
                  return (
                    <tr key={employee.employee_id} className={rowClass}>
                      <td className="px-6 py-4 whitespace-nowrap text-t text-gray-500">
                        {moment(employee.checkIN_time).format("D-MMM-YY")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                        {employee.employee_id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                        {moment(employee.checkIN_time).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                        {employee.checkOUT_time
                          ? moment(employee.checkOUT_time).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : "- -"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-md text-black">
                        <span
                          className={`px-2 inline-flex text-md leading-5 font-semibold rounded-full ${getStatusStyle(
                            employee.status
                          )}`}
                        >
                          {employee.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                        {employee.department}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-t text-gray-900">
                        {employee.address}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-start py-3">
        <div>
          Showing{" "}
          {filteredEmployeeData.length > 0
            ? (currentPage - 1) * rowsPerPage + 1
            : 0}{" "}
          to{" "}
          {currentPage * rowsPerPage > filteredEmployeeData.length
            ? filteredEmployeeData.length
            : currentPage * rowsPerPage}{" "}
          of {filteredEmployeeData.length} records
        </div>
        <div className="flex space-x-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === page ? "bg-gray-300" : "bg-white"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheckinCheckoutReportTable;
