/** @format */

import React, { useState, useEffect } from "react";
import moment from "moment";
import LeaveDataTable from "./emp_leave_table";
import AddLeaveQuotaForm from "./add_leave_quota";
import usePermissions from "../../../permissions/permission";

const EmpLeaveManagement = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [leaveData, setLeaveData] = useState([]); // State to hold leave data
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { hasPermission } = usePermissions();

  const currentDate = moment().format("MMMM Do YYYY");

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleResetLeaveCount = () => {
    setShowConfirmDialog(true);
  };

  const confirmResetLeaveCount = async () => {
    try {
      const response = await fetch(
        "https://back-trial-hris-ke.talentfort.live/v1/hris/leave/resetleavecounts",
        {
          method: "PUT",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to reset leave counts");
      }

      const data = await response.json();
      setSuccessMessage(data.message);

      // Update the leaveData state to reset current_leave_counts
      const updatedLeaveData = leaveData.map((employee) => ({
        ...employee,
        current_leave_counts: {
          shortleavecount: 0,
          halfdaycount: 0,
          anualleavecount: 0,
          casualleavecount: 0,
          medicalleavecount: 0,
        },
      }));

      setLeaveData(updatedLeaveData);
      setShowConfirmDialog(false);
    } catch (error) {
      console.error("Error resetting leave counts:", error);
      setSuccessMessage("Error resetting leave counts");
    }
  };

  // Fetch leave data when the component mounts
  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = async () => {
    try {
      const response = await fetch(
        "https://back-trial-hris-ke.talentfort.live/v1/hris/leave/GetLeaveCountstoallemployee"
      );
      const data = await response.json();
      setLeaveData(data);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  const handleFormSubmitSuccess = () => {
    setIsFormOpen(false); // Close the form popup
    fetchLeaveData(); // Refresh the table data
  };

  return (
    <div className="mx-10 mt-5">
      <div className="overflow-x-hidden mr-[2%]">
        <div className="flex justify-between items-center mt-6">
          <div>
            <p className="text-[30px] font-semibold">
              Employee Leave Management
            </p>
            <p className="text-[15px] font-semibold text-[#3700B3]">
              Employee Leave Management
            </p>
          </div>
        </div>
        <div className="mt-5">
          <div>
            {hasPermission(3300) && (
              <button
                className="bg-[#3700B3] text-white p-3 rounded-[12px] font-semibold"
                onClick={handleResetLeaveCount}
              >
                Reset Leave Count
              </button>
            )}
          </div>
        </div>

        <div className="mt-5">
          <div>
            {hasPermission(3310) && (
              <button
                className="bg-[#3700B3] text-white p-3 rounded-[12px] font-semibold"
                onClick={toggleForm}
              >
                Add Leave Quota
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="overflow-x-auto mr-[5%]">
        <LeaveDataTable
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          leaveData={leaveData}
        />
      </div>
      {isFormOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-3/4 max-w-3xl">
            <AddLeaveQuotaForm onSuccess={handleFormSubmitSuccess} />
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
              onClick={toggleForm}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {showConfirmDialog && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>Are you sure you want to reset all leave counts to 0?</p>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={confirmResetLeaveCount}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded"
                onClick={() => setShowConfirmDialog(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {successMessage && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>{successMessage}</p>
            <button
              className="mt-4 bg-[#6A2E92] text-white px-4 py-2 rounded"
              onClick={() => setSuccessMessage("")}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmpLeaveManagement;
