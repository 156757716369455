/** @format */

import React, { useState, useEffect } from "react";
import Navbar from "../../navbar/navbar";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import Modal from "react-modal";
import usePermissions from "../../../permissions/permission";

registerLocale("en-GB", enGB);

Modal.setAppElement("#root");

const Restricted_Date = () => {
  const [restrictedDates, setRestrictedDates] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [reason, setReason] = useState("");
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchRestrictedDates = async () => {
      try {
        const response = await fetch(
          "https://back-trial-hris-ke.talentfort.live/v1/hris/daterestriction/getAllDataRestrictions"
        );
        const data = await response.json();
        const formattedData = data.map((item) => ({
          date: item.restricted_dates,
          reason: item.reason_for_restriction,
        }));
        setRestrictedDates(formattedData);
      } catch (error) {
        console.error("Error fetching restricted dates:", error);
      }
    };

    fetchRestrictedDates();
  }, []);

  const handleDateChange = (date) => {
    const dateString = moment(date).format("YYYY-MM-DD");
    setSelectedDate(dateString);
    const existingDate = restrictedDates.find((d) => d.date === dateString);
    if (existingDate) {
      setReason(existingDate.reason);
    } else {
      setReason("");
    }
    setModalIsOpen(true);
  };

  const saveDate = async () => {
    const data = {
      restricted_date: selectedDate,
      reason_for_restriction: reason,
    };

    try {
      const response = await fetch(
        "https://back-trial-hris-ke.talentfort.live/v1/hris/leave/addRestrictedDate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Save response:", result);

        setRestrictedDates((prev) => {
          const existingDate = prev.find((d) => d.date === selectedDate);
          if (existingDate) {
            return prev.map((d) =>
              d.date === selectedDate ? { date: selectedDate, reason } : d
            );
          } else {
            return [...prev, { date: selectedDate, reason }];
          }
        });
        setModalIsOpen(false);
      } else {
        const errorText = await response.text();
        console.error("Failed to add restricted date:", errorText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const deleteDate = async () => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD"); // Ensure the date format matches the expected format

    try {
      const response = await fetch(
        `https://back-trial-hris-ke.talentfort.live/v1/hris/timetable/deleteDataRestrictions?date=${formattedDate}`,
        {
          method: "DELETE", // Use DELETE method
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        console.log("Delete response:", await response.json());

        setRestrictedDates((prev) =>
          prev.filter((d) => d.date !== selectedDate)
        );
        setModalIsOpen(false);
      } else {
        const errorText = await response.text();
        console.error("Failed to delete restricted date:", errorText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="mx-10 mt-5 overflow-y-auto">
      <div className="flex justify-between mt-6">
        <div>
          <p className="text-[30px] font-semibold">Date Restrictions</p>
          <p>
            Leave <span className="text-[#6A2E92]">Date Restrictions</span>
          </p>
        </div>
      </div>

      <div className="flex justify-between items-center mx-[5%] mt-10">
        <div>
          <h2 className="text-red-500 text-[35px] font-bold text-center">
            Restricted Dates
          </h2>
          <div className="flex justify-center mt-5">
            <DatePicker
              inline
              selected={null}
              onChange={handleDateChange}
              highlightDates={restrictedDates.map((d) => new Date(d.date))}
              locale="en-GB"
              dayClassName={(date) =>
                restrictedDates.some(
                  (d) => d.date === moment(date).format("YYYY-MM-DD")
                )
                  ? "bg-red-500 text-white"
                  : undefined
              }
              renderCustomHeader={({
                monthDate,
                decreaseMonth,
                increaseMonth,
              }) => (
                <div className="flex justify-between mb-2">
                  <button onClick={decreaseMonth}>&lt;</button>
                  <span>{moment(monthDate).format("MMMM YYYY")}</span>
                  <button onClick={increaseMonth}>&gt;</button>
                </div>
              )}
            />
          </div>
        </div>
        <div>
          <h3 className="text-purple-700 text-[35px] font-semibold">
            Instructions
          </h3>
          <ul className="list-disc ml-5 mt-2">
            <li>
              Click on the required date on the calendar to lock the date and
              continue the restricting
            </li>
            <li>
              To Update a restriction, click on the specific date and update the
              status.
            </li>
            <li>
              To Delete a restriction, click on the specific date and select
              delete restriction. Press Confirm on the Confirmation Screen.
            </li>
          </ul>
          <div className="mt-5">
            <div className="flex items-center">
              <div className="bg-red-500 w-6 h-6 mr-2"></div>
              <span>Restricted Date</span>
            </div>
            <div className="flex items-center mt-2">
              <div className="bg-gray-300 w-6 h-6 mr-2"></div>
              <span>Available Date</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Restricted Date"
        className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75"
      >
        <div className="bg-white text-black p-5 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-2">Restricted Date</h2>
          <label htmlFor="reason" className="block mb-2">
            Reasons:
            <input
              type="text"
              id="reason"
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="mt-1 p-2 border border-gray-300 rounded-md text-black w-full"
            />
          </label>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setModalIsOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
            >
              Cancel
            </button>
            {hasPermission(3410) && (
              <button
                onClick={saveDate}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Save
              </button>
            )}
            {hasPermission(3420) && (
              <button
                onClick={deleteDate}
                className="bg-red-500 text-white px-4 py-2 rounded-md ml-2"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Restricted_Date;
